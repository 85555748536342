import React from 'react';
import notfound from '../assets/images/notfound.avif';

function Notfound(props) {
    return (
        <div className="flex flex-col items-center justify-center h-full">
            <img
                src={notfound}
                alt="Not found"
                className="w-full max-w-xs rounded-2xl sm:max-w-sm md:max-w-md lg:max-w-lg xl:max-w-xl "

            />
            <h3 className='maintitle my-6 text-3xl font-semibold text-center headlandfont'>{props.title}</h3>
        </div>
    );
}

export default Notfound;
