import React from 'react';
import ApexCharts from 'react-apexcharts';

const ApexChart = ({ orders }) => {

    const options = {
        series: [
            { name: "Weekly", data: orders.weeklycount },
            { name: "Monthly", data: orders.monthlycount },
            { name: "Total", data: orders.totalcount }
        ].map(item => item.data),  // Extracting data for the series
        labels: ["Weekly", "Monthly", "Total"], // Labels corresponding to the series
        chart: {
            width: 380,
            type: 'donut',
        },
        dataLabels: {
            enabled: false
        },
        responsive: [{
            breakpoint: 480,
            options: {
                chart: {
                    width: 200
                },
                legend: {
                    show: false
                }
            }
        }],
        legend: {
            position: 'right',
            offsetY: 0,
            height: 230,
        }
    };

    return (
        <div id="chart">
            <ApexCharts options={options} series={options.series} labels={options.labels} type="donut" />
        </div>
    );
};

export default ApexChart;
