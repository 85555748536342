import { Outlet, Navigate } from "react-router-dom";
import Sidebar from '../component/Sidebar';

function Layout() {
    const token = localStorage.getItem('token'); // Assuming token is stored in localStorage

    if (!token) {
        return <Navigate to="/login" replace />;
    }

    return (
        <>
            <Sidebar content={<Outlet />} />
        </>
    );
}

export default Layout;
