import React from 'react'

function DashboardComp(props) {
    return (
        <>
            <div className="bg-white shadow rounded-lg overflow-hidden">
                <div className="p-4 flex items-center">
                    <div className="w-1/2">
                        <div className="bg-[rgb(255,108,47)] h-[5rem] w-[5rem] bg-opacity-25 p-4 rounded-full">
                            <img
                                src={props.img}
                                alt={props.type}
                            />
                        </div>
                    </div>
                    <div className="w-1/2 text-right">
                        <p className="text-gray-500 text-sm capitalize font-bold">
                            {props.title}
                        </p>
                        <h3 className="text-gray-800  text-orange text-4xl font-bold mt-1">
                            {props.data[props.type.toLowerCase()] || 0}
                        </h3>
                    </div>
                </div>
                <div className="bg-gray-100 p-2 flex items-center justify-between">
                    {/* Add additional content here if needed */}
                </div>
            </div>
        </>
    )
}

export default DashboardComp