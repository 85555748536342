import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import front from "../../assets/images/frontadhar.webp"
import pancard from "../../assets/images/pancard.jpeg"
import banner from "../../assets/images/profilebanner.jpg"
import TitleComp from '../../component/TitleComp'
import approve from "../../assets/images/approved.png"
import reject from "../../assets/images/rejected.png"

function Userinfo() {

    const { state } = useLocation()
    const navigate = useNavigate()

    console.log(state)

    return (
        <>
            <section className='hidden' style={{ background: "#ec5f1a", height: "200px", borderRadius: "30px" }} >
                <div className="grid grid-cols-3 ">
                    <div className="col-span-3 relative">

                        <div className="absolute text-4xl top-10 w-full   text-white ">
                            <TitleComp title="User Info" />
                        </div>
                    </div>
                </div>


            </section>
            <section className='p-3 mt-3'>
                <div className="container">
                    <div className="grid grid-cols-4 gap-10 mt-6">
                        <div className="col-span-4">
                            <div className="text-end">
                                <p className='text-red-500'><i class="fa-solid fa-circle-info"></i> If you view the Aadhar card's back details, it will automatically flip when you hover over it.</p>
                            </div>


                        </div>
                        <div className="col-span-2">
                            <div className="shadow bg-white rounded-md">
                                <div className="bg-stone-200 p-3 rounded-md">
                                    <h2 className="text-3xl  font-normal">
                                        Detail
                                    </h2>
                                </div>
                                <div className="p-3">
                                    <div className="mb-4 grid grid-cols-2 gap-2 ">
                                        <h3 className="font-medium text-md">Active</h3>
                                        <img src={state?.is_active ? approve : reject} style={{ height: "30px" }} alt="" />
                                    </div>
                                    <div className="mb-4 grid grid-cols-2 gap-2 ">
                                        <h3 className="font-medium text-md">Phone Verified</h3>
                                        <img src={state?.is_phone_verified ? approve : reject} style={{ height: "30px" }} alt="" />
                                    </div>

                                    {state?.phone && <div className="mb-4 grid grid-cols-2 gap-2">
                                        <h3 className="font-medium text-md">Phone</h3>
                                        <h4>{state?.phone}</h4>
                                    </div>}

                                    {state?.gender && <div className="mb-4 grid grid-cols-2 gap-2">
                                        <h3 className="font-medium text-md">Gender</h3>
                                        <h4>{state?.gender}</h4>
                                    </div>}
                                    {state?.dob && <div className="mb-4 grid grid-cols-2 gap-2">
                                        <h3 className="font-medium text-md">Date Of Birth</h3>
                                        <h4>{state?.dob}</h4>
                                    </div>}

                                    {state?.shopname && <div className="mb-4 grid grid-cols-2 gap-2">
                                        <h3 className="font-medium text-md">Shop Name</h3>
                                        <h4>{state?.shop_name}</h4>
                                    </div>}
                                    <div className="mb-4 grid grid-cols-2 gap-2">
                                        <h3 className="font-medium text-md">Bank Edit</h3>
                                        <img src={state?.can_edit_bank ? approve : reject} style={{ height: "30px" }} alt="" />
                                    </div>
                                    <div className="mb-4 grid grid-cols-2 gap-2">
                                        <h3 className="font-medium text-md">Bank Edit Request</h3>
                                        <img src={state?.request_edit_bank ? approve : reject} style={{ height: "30px" }} alt="" />
                                    </div>
                                    <div className="mb-4 grid grid-cols-2 gap-2">
                                        <h3 className="font-medium text-md">Document Edit</h3>
                                        <img src={state?.can_edit_docs ? approve : reject} style={{ height: "30px" }} alt="" />
                                    </div>
                                    <div className="mb-4 grid grid-cols-2 gap-2">
                                        <h3 className="font-medium text-md">Document Edit Request</h3>
                                        <img src={state?.request_edit_docs ? approve : reject} style={{ height: "30px" }} alt="" />
                                    </div>
                                    <div className="mb-4 grid grid-cols-2 gap-2 tablebox ">
                                        <h3 className="font-medium text-md">Roles</h3>
                                        <button className=' w-max cutombtn' onClick={(e) => navigate(`/addpermission/vendor`, { state: state })}><i class="fa-solid fa-user-pen"></i> </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-span-2">
                            <div className="flex gap-10">

                                <div class="boxclip">
                                    <div class=" clipcard">
                                        <div class="front">
                                            <img src={front} className='rounded-2xl' alt="" />
                                            <p class="front-heading">Front card</p>

                                        </div>
                                        <div class="back">
                                            <img src={front} className='rounded-2xl' alt="" />
                                            <p class="back-heading">Back card</p>

                                        </div>
                                    </div>
                                </div>
                                <div class="boxclip">
                                    <div class="">
                                        <div class="front">
                                            <img src={pancard} className='rounded-2xl' alt="" />
                                            <p class="front-heading">Pan card</p>

                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Userinfo
