import React from 'react'

function Setting() {
    return (
        <>
            Setting
        </>
    )
}

export default Setting
