import axios from "axios";
import { BASE_URL } from "./BaseUrl";



export const superadminloginapi = async (endpoint, requestdata) => {
    try {
        const items = await axios.post(`${BASE_URL}superadmin/${endpoint}`, requestdata);
        return items.data;
    } catch (errors) {
        console.log(errors);
        return { data: errors }
    }
}

export const superadminpostapi = async (endpoint, requestdata, token) => {
    const headers = {
        Authorization: `Bearer ${token}`
    }
    try {
        const items = await axios.post(`${BASE_URL}superadmin/${endpoint}`, requestdata, { headers });
        return items.data;
    } catch (errors) {
        console.log(errors);
        return { data: errors }
    }
}

export const superadminputapi = async (endpoint, requestdata, token) => {
    const headers = {
        Authorization: `Bearer ${token}`
    }
    try {
        const items = await axios.put(`${BASE_URL}superadmin/${endpoint}`, requestdata, { headers });
        return items.data;
    } catch (errors) {
        console.log(errors);
        return { data: errors }
    }
}

export const superadmingetapi = async (endpoint, token) => {
    const headers = {
        Authorization: `Bearer ${token}`
    }
    try {
        const items = await axios.get(`${BASE_URL}superadmin/${endpoint}`, { headers });
        return items.data;
    } catch (errors) {
        console.log(errors);
        return { data: errors.response.data }
    }
}


export const mastergetapi = async (endpoint, token) => {
    const headers = {
        Authorization: `Bearer ${token}`
    }
    try {
        const items = await axios.get(`${BASE_URL}master/${endpoint}`, { headers });
        return items.data;
    } catch (errors) {
        console.log(errors);
        return { data: errors }
    }
}

export const masterpostapi = async (endpoint, requestdata, token) => {
    const headers = {
        Authorization: `Bearer ${token}`
    }
    try {
        const items = await axios.post(`${BASE_URL}master/${endpoint}`, requestdata, { headers });
        return items.data;
    } catch (errors) {
        console.log(errors);
        return { data: errors }
    }
}

export const masterputapi = async (endpoint, requestdata, token) => {
    const headers = {
        Authorization: `Bearer ${token}`
    }
    try {
        const items = await axios.put(`${BASE_URL}master/${endpoint}`, requestdata, { headers });
        return items.data;
    } catch (errors) {
        console.log(errors);
        return { data: errors.response.data }
    }
}


export const masterdeleteapi = async (endpoint, requestdata, token) => {

    const headers = {
        Authorization: `Bearer ${token}`
    }
    try {
        const items = await axios.delete(`${BASE_URL}master/${endpoint}`, requestdata, { headers });
        return items.data;
    } catch (errors) {
        console.log(errors);
        return { data: errors.response.data }
    }
}