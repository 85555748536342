import React, { useEffect, useState } from 'react'
import { superadmingetapi, superadminputapi } from '../../Api/Api'
import TitleComp from '../../component/TitleComp'
import notfound from "../../assets/images/notfound.avif"
import Loader from '../../component/Loader'
import { BASE_URL } from '../../Api/BaseUrl'
import TableComp from '../../component/TableComp'
import convertDateToMonthName from '../../component/FormDate'
import Modal from '../../component/Modal'
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom'

function OrderHistory() {
    let userid = localStorage.getItem("user_id")
    let token = localStorage.getItem("token")
    const [loading, setloading] = useState(false)
    const [data, setdata] = useState([])

    const [show, setshow] = useState(false)

    const [orderid, setorderid] = useState("")

    const [status, setStatus] = useState('');
    const [remark, setRemark] = useState('');

    const handleget = async () => {
        setloading(true);
        try {
            let res = await superadmingetapi('orders', token);
            if (res.error) {
                throw new Error(res.error);
            }
            setdata(res.data);
        } catch (error) {
            console.error('Error fetching orders:', error);

        } finally {
            setloading(false);
        }
    };





    useEffect(() => {
        handleget()

    }, [])

    const navigate = useNavigate()

    const columns = [
        {
            field: "sub_orders",
            headerName: "Sub Order",
            flex: 1,
            renderCell: (params) => params.row.sub_orders?._id || 'N/A',
        },
        {
            field: "user.name",
            headerName: "User Name",
            flex: 1,
            renderCell: (params) => params.row.user?.name || 'N/A',
        },
        {
            field: "vendor.name",
            headerName: "Vendor Name",
            flex: 1,
            renderCell: (params) => params.row.vendor?.name || 'N/A',
        },
        {
            field: "store.name",
            headerName: "Store Name",
            flex: 1,
            renderCell: (params) => params.row.store?.name || 'N/A',
        },
        {
            field: "product_variant_id.name",
            headerName: "Product Name",
            width: 250,
            renderCell: (params) => params.row.product_variant_id?.name || 'N/A',
        },

        { field: "qty", headerName: "Quantity", width: 100 },
        {
            field: "selling_price",
            headerName: "Selling Price",
            flex: 1,
            renderCell: (params) => {
                const price = params.value;
                return (
                    <span className='text-emerald-400'> {/* Emerald color in Tailwind CSS is #10B981 */}
                        ₹{price.toFixed(2)} {/* Display price with two decimal places */}
                    </span>
                );
            },
        },
        // {
        //     field: "item_order_status",
        //     headerName: "Order Status",
        //     flex: 1,
        //     renderCell: (params) => {
        //         const status = params.value;

        //         // Define badge styles based on status
        //         const badgeStyles = {
        //             Pending: "bg-yellow-500 text-white",
        //             Delivered: "bg-green-500 text-white",
        //             Processing: "bg-blue-500 text-white",
        //             Shipped: "bg-orange-500 text-white",
        //             Completed: "bg-purple-500 text-white",
        //             Accepted: "bg-teal-500 text-white",
        //             Rejected: "bg-red-500 text-white",
        //         };

        //         return (
        //             <span className={`px-3 cursor-pointer py-1 rounded-full text-xs font-semibold ${badgeStyles[status] || "bg-gray-500 text-white"}`} onClick={(e) => {
        //                 setStatus(status);
        //                 setshow(true);
        //                 setorderid(params.row._id);
        //                 setRemark(params.row.shipping_remarks?.[params.row.shipping_remarks.length - 1]?.remark || '');
        //             }}>
        //                 {status} <i class="fa-solid fa-angle-down"></i>
        //             </span>
        //         );
        //     },
        // },
        {
            field: "info",
            headerName: "info",
            flex: 1,
            renderCell: (params) => <button className='cutombtn' onClick={(e) => navigate('/orderdetail', { state: params.row })}><i class="fa-solid fa-circle-info"></i></button>,
        },
        {
            field: "created_at",
            headerName: "Created At",
            width: 150,
            renderCell: (params) => convertDateToMonthName(params.row.created_at),
        },
    ];

    const handlestatusorder = async (e) => {
        e.preventDefault()
        setloading(true)
        let requestdata = {
            _id: orderid,
            shipping_remarks: {
                status: status,
                remark: remark,
                user: userid
            },

            item_order_status: status,

        }
        let res = await superadminputapi('order_status_remarkss', requestdata, token)
        if (!res.error) {
            setloading(false)
            toast.success("Order Status Change Succesfully")
            setshow(false)
            handleget()

        } else {
            setloading(false)
        }
    }


    const itemOrderStatus = [
        'Pending',
        'Processing',
        'Accepted',
        'Shipped',
        'Delivered',
        'Completed',
        'Rejected'
    ];
    const getRowId = (row) => row._id
    const content = (
        <form onSubmit={handlestatusorder}>
            <div className="p-4">
                <div className="mb-4 relative">
                    <label htmlFor="status" className="block text-gray-700 text-sm font-bold mb-2">
                        Order Status
                    </label>
                    <select
                        id="status"
                        value={status}
                        onChange={(e) => setStatus(e.target.value)}
                        className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 pr-10"
                    >
                        <option value="">Select status</option>
                        {itemOrderStatus.map((status) => (
                            <option key={status} value={status}>
                                {status}
                            </option>
                        ))}
                    </select>
                    <div className="absolute inset-y-0 right-0 top-6 flex items-center px-2 pointer-events-none">
                        <i class="fa-solid fa-angle-down"></i>
                    </div>
                </div>
                <div>
                    <label htmlFor="remark" className="block text-gray-700 text-sm font-bold mb-2">
                        Remarks
                    </label>
                    <textarea
                        id="remark"
                        value={remark}
                        onChange={(e) => setRemark(e.target.value)}
                        className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        rows="4"
                    />
                </div>
                <button className='btn bg-blue-500 p-2 mt-3 rounded-md text-white'>Change Order Status</button>
            </div>
        </form>
    );

    const reversedData = Array.isArray(data) ? [...data].reverse() : [];

    return (
        <>
            {show && <Modal onCancel={() => setshow(false)} content={content} />}

            {loading && <Loader />}
            <section>
                <div className="container">
                    <TitleComp title="Order History" />
                    <div className="grid grid-cols-4 gap-8">
                        {/* <div className="col-span-1">
                            <div className="flex mb-4  mt-4">
                                <div className="w-full">
                                    <label htmlFor="">Filter User</label>
                                    <select className="appearance-none block w-full bg-white border border-gray-300 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500">
                                        <option value="">Select an option</option>
                                        {userdata?.map((item) => {
                                            return (
                                                <>
                                                    <option value={item._id}>{item.name}</option>
                                                </>
                                            )
                                        })}
                                    </select>
                                    <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                                        <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M10 12l-5-5h10l-5 5z" /></svg>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className="col-span-1">
                            <div className="flex mb-4  mt-4">
                                <div className="w-full">
                                    <label htmlFor="">Filter Vendor</label>
                                    <select className="appearance-none block w-full bg-white border border-gray-300 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500">
                                        <option value="">Select an option</option>
                                        <option value="1">Option 1</option>
                                        <option value="2">Option 2</option>
                                        <option value="3">Option 3</option>
                                    </select>
                                    <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                                        <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M10 12l-5-5h10l-5 5z" /></svg>
                                    </div>
                                </div>
                            </div>

                        </div> */}
                        <div className="col-span-4">
                            <TableComp data={reversedData} columns={columns} getRowId={getRowId} />
                        </div>
                    </div>

                </div>
            </section>
        </>
    )
}

export default OrderHistory







{/* <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-4 gap-6">
                        {data?.length > 0 && data?.map((item) => {
                            return (
                                <>
                                    <div className="col-span-1">
                                        <div class="card">
                                            <div class="image-container">

                                                <img
                                                    src={`${BASE_URL}${item.product.image.file}`}
                                                    alt={item.product.name}
                                                    loading='lazy'
                                                    onError={(e) => e.target.src = notfound}
                                                    className="w-full h-full object-cover"
                                                />

                                                <div class="price">  <del className='text-red-600'>₹{item.unit_mrp}</del> <span className='text-emerald-600'>₹{item.unit_selling_price}</span></div>
                                            </div>
                                           


                                            <div class="content">
                                                <div class="brand">{item.store.name}</div>
                                                <div class="product-name mb-0" style={{ marginBottom: "5px" }}>{item.product.name}</div>
                                              
                                                <div className="product-name">
                                                    <span className='text-emerald-400'>{item.qty} Items &#9864; <span className='text-[#ec5f1a] text-sm ms-3'>₹{item.selling_price}</span></span>
                                                </div>



                                            </div>

                                            <div className="mb-3">
                                                <div className={`flex gap-6 ps-2 `}>

                                                    {item.user.name && <div class="uiverse">
                                                        <span class="tooltip1">User</span>
                                                        <span className='tooltipname'>
                                                            <i class="fa-solid fa-user"></i> {item.user.name}
                                                        </span>
                                                    </div>}

                                                    {item.vendor.name && <div class="uiverse">
                                                        <span class="tooltip1">Vendor</span>
                                                        <span className='tooltipname'>
                                                            <i class="fa-solid fa-shop"></i> {item.vendor.name}
                                                        </span>
                                                    </div>}
                                                </div>
                                            </div>



                                         
                                        </div>
                                    </div>
                                </>
                            )
                        })}
                    </div> */}