import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import "../assets/css/login.css";
import logo from "../assets/images/logo.png";
import { postapi, superadminloginapi } from '../Api/Api';
import { toast, ToastContainer } from 'react-toastify';
import OTPInput from 'react-otp-input';
import Loader from '../component/Loader';

const Login = () => {
    const [phone, setPhone] = useState("");
    const [loading, setloading] = useState(false)
    const [check, setCheck] = useState(false);
    const [otp, setOtp] = useState("");
    const [checkOtp, setCheckOtp] = useState("");
    const [isVisible, setIsVisible] = useState(false);
    const [showSplash, setShowSplash] = useState(true);
    const [circleStyle, setCircleStyle] = useState({
        height1: '50vh',
        width1: '53vw',
        height2: '60vh',
        width2: '50vw',
        padding: '30px',
    });

    const navigate = useNavigate();

    useEffect(() => {
        const splashTimer = setTimeout(() => {
            setShowSplash(false);
        }, 2000);

        return () => clearTimeout(splashTimer);
    }, []);

    useEffect(() => {
        if (!showSplash) {
            const timer = setTimeout(() => {
                setIsVisible(true);
            }, 100); // Delay to show the effect

            return () => clearTimeout(timer);
        }
    }, [showSplash]);

    useEffect(() => {
        if (showSplash) {
            const animationTimer = setTimeout(() => {
                setCircleStyle({
                    height1: '400vh',
                    width1: '400vw',
                    height2: '400vh',
                    width2: '400vw',
                    padding: '150px',
                });
            }, 400);
            return () => clearTimeout(animationTimer);
        }
    }, [showSplash]);

    const handleNumber = async (e) => {
        e.preventDefault();
        setloading(true)
        let request = { phone: phone };
        let res = await superadminloginapi('username', request);
        if (res.misc === "Old") {
            setCheck(true);
            toast.success(res.data);
            setCheckOtp(res.data);
            setloading(false)
        } else {
            toast.error("You cannot log in");

            setloading(false)
        }
    };

    const handleOtp = async (e) => {
        e.preventDefault();
        setloading(true)
        if (checkOtp == otp) {
            let request = { phone: phone, otp: otp };
            let res = await superadminloginapi('verify_otp', request);
            if (res.misc == "Old") {
                localStorage.setItem("user_id", res.data.user_id);
                localStorage.setItem("token", res.data.token);
                localStorage.setItem("usertypeid", res.data.user_type._id);
                localStorage.setItem("username", res.data.user_type.name);
                navigate("/dashboard");
                setloading(false)
            }
        } else {
            toast.error("Otp Mismatch");
            setloading(false)
        }
    };

    return <>
        {loading && <Loader />}
        {showSplash ? (
            <div className="splash-container overflow-hidden">
                <div className="splash-content">
                    <img src={logo} alt="Logo" className="logo" />
                    <h4>STORO</h4>
                </div>
                <div className="animated-circle" style={{ ...circleStyle, right: '-70px', top: '-70px' }}></div>
                <div className="animated-circle" style={{ ...circleStyle, left: '-90px', bottom: '-80px' }}></div>
            </div>
        ) : (
            <div className={`flex justify-center items-center min-h-screen ${isVisible ? 'opacity-100' : 'opacity-0'} transition-opacity duration-1000 ease-in-out`}>
                <ToastContainer />
                <div className="loginbox bg-white p-6 rounded-lg shadow-lg transition-transform duration-300 ease-in-out transform">
                    <div className="heading flex justify-center mb-6">
                        <div>
                            <div className="flex justify-center">
                                <img src={logo} alt="Logo" className="w-24 h-auto" />
                            </div>
                            <h4>Sign In Storo</h4>
                        </div>
                    </div>
                    <form className="form " onSubmit={!check ? handleNumber : handleOtp}>
                        <div className="form-group">
                            <label htmlFor="phone" className="block text-gray-700">Phone</label>
                            <input
                                required
                                className="input w-full p-2 border border-gray-300 rounded-md shadow-sm transition-opacity duration-300 ease-in-out focus:outline-none focus:ring-2 focus:ring-blue-500"
                                value={phone}
                                onChange={(e) => setPhone(e.target.value)}
                                type="phone"
                                minLength={10}
                                maxLength={10}
                                name="phone"
                                id="phone"
                                placeholder="Enter Your Phone Number"
                            />
                        </div>
                        {check && (
                            <div className="form-group OtpBox">
                                <label htmlFor="otp" className="block mb-3 text-gray-700">Otp</label>
                                <OTPInput
                                    value={otp}
                                    onChange={setOtp}
                                    numInputs={4}
                                    className="form-control"
                                    renderSeparator={<span className="me-2"> </span>}
                                    renderInput={(props) => <input {...props} />}
                                />
                            </div>
                        )}
                        <input className="login-button w-full p-2 bg-blue-500 text-white rounded-md cursor-pointer transition-transform duration-300 ease-in-out transform hover:scale-105" type="submit" value={!check ? "Send Otp " : "Submit"} />
                    </form>
                </div>
            </div>
        )}
    </>
};

export default Login;
