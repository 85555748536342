import React from 'react';
import ApexCharts from 'react-apexcharts';

const PerformanceChart = ({ salesData }) => {
    const options = {
        series: [{
            name: "Sales",
            data: [
                salesData.todaysSales,
                salesData.weeklySales,
                salesData.monthlySales,
                salesData.totalSales
            ],
        }],
        chart: {
            height: 350,
            type: 'donut',
            toolbar: {
                show: false,
            },
        },
        plotOptions: {
            bar: {
                columnWidth: '50%',
                borderRadius: 5,
            }
        },
        fill: {
            opacity: 0.9,
        },
        xaxis: {
            categories: ["Today", "This Week", "This Month", "Total"],
            axisTicks: {
                show: false,
            },
            axisBorder: {
                show: false,
            },
            labels: {
                style: {
                    colors: '#9aa0ac',
                },
            }
        },
        yaxis: {
            labels: {
                style: {
                    colors: '#9aa0ac',
                },
            },
        },
        grid: {
            show: true,
            borderColor: '#e0e0e0',
            strokeDashArray: 4,
        },
        colors: ['#ff6c2f', '#22c55e', '#3b82f6', '#f59e0b'], // Colors for each bar
        tooltip: {
            y: {
                formatter: function (value) {
                    return value.toLocaleString(); // Add formatting as needed
                }
            }
        },
    };

    return (
        <div id="dash-performance-chart">
            <ApexCharts options={options} series={options.series} type="bar" height={350} />
        </div>
    );
};

export default PerformanceChart;
