import React from 'react';

const Modal = ({ onCancel, content }) => {
    return (
        <div className="fixed inset-0 overflow-y-auto bg-gray-800 bg-opacity-50 flex items-center justify-center" style={{ zIndex: "999999" }}>
            <div className="bg-white  rounded-lg shadow-lg w-1/3">
                <div className="flex justify-between items-center  bg-gray-200 p-3 rounded-t-lg">
                    <h2 className="text-lg font-semibold">Order Status</h2>
                    <button
                        onClick={onCancel}
                        className="bg-white shadow-sm rounded-2xl px-3 py-1 text-gray-500 hover:text-gray-700"
                    >
                        <i className="fa-solid fa-xmark"></i>
                    </button>
                </div>
                <div className='p-3'>
                    {content}
                </div>
            </div>
        </div>
    );
};

export default Modal;
