import React, { useEffect, useState } from 'react';
import TitleComp from '../../component/TitleComp';
import { superadminpostapi, superadminputapi } from '../../Api/Api';
import Loader from '../../component/Loader';
import { toast } from 'react-toastify';
import { useLocation, useNavigate } from 'react-router-dom';

function AddCoupon() {
    let token = localStorage.getItem("token");
    const navigate = useNavigate();
    const { state } = useLocation();
    console.log(state)
    const [loading, setloading] = useState(false);
    const [formData, setFormData] = useState({
        name: '',
        description: '',
        off_type: '',
        off_value: '',
        threshold: '',
        frequency: '',
        max_off: '',
        start_date: '',
        end_date: '',
        code_by: "Admin",
    });
    const [image, setImage] = useState(null);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value
        }));
    };

    const handleImageChange = (e) => {
        setImage(e.target.files[0]);
    };

    const handleEdit = () => {
        setFormData({
            name: state.name,
            description: state.description,
            off_type: state.off_type,
            off_value: state.off_value,
            threshold: state.threshold,
            frequency: state.frequency,
            max_off: state.max_off,
            start_date: state.start_date?.substring(0, 10),
            end_date: state.end_date?.substring(0, 10),
            code_by: "Admin",
        });
    };

    useEffect(() => {
        if (state) {
            handleEdit();
        }
    }, [state]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setloading(true);

        const formDataToSend = new FormData();
        formDataToSend.append('name', formData.name);
        formDataToSend.append('description', formData.description);
        formDataToSend.append('off_type', formData.off_type);
        formDataToSend.append('off_value', formData.off_value);
        formDataToSend.append('threshold', formData.threshold);
        formDataToSend.append('frequency', formData.frequency);
        formDataToSend.append('max_off', formData.max_off);
        formDataToSend.append('start_date', formData.start_date);
        formDataToSend.append('end_date', formData.end_date);
        formDataToSend.append('code_by', formData.code_by);

        if (image) {
            formDataToSend.append('image', image);
        }

        try {
            let res = "";

            if (state) {
                formDataToSend.append('_id', state._id);
                res = await superadminputapi('coupons', formDataToSend, token);
            } else {
                res = await superadminpostapi('coupons', formDataToSend, token);
            }

            if (res.error) {
                toast.error(`Error: ${res.error.message || 'An unexpected error occurred.'}`);
            } else {
                toast.success(`Data ${state ? "Updated" : "submitted"} successfully`);
                navigate("/couponlist");
                setFormData({
                    name: '',
                    description: '',
                    off_type: '',
                    off_value: '',
                    threshold: '',
                    frequency: '',
                    max_off: '',
                    start_date: '',
                    end_date: '',
                    code_by: "Admin",
                });
                setImage(null);
            }
        } catch (error) {
            toast.error(`Error: ${error.message || 'An unexpected error occurred.'}`);
        } finally {
            setloading(false);
        }
    };

    return (
        <>
            {loading && <Loader />}
            <section className="p-6 bg-white rounded-lg shadow-md">
                <TitleComp title={state ? "Update Coupon" : "Add New Coupon"} />
                <form onSubmit={handleSubmit} className="grid grid-cols-3 gap-4">
                    <div className="col-span-1">
                        <label htmlFor="name" className="block text-sm font-medium text-gray-700">Name</label>
                        <input
                            type="text"
                            id="name"
                            name="name"
                            value={formData.name}
                            onChange={handleChange}
                            className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-300 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                            placeholder="Enter Name"
                        />
                    </div>
                    <div className="col-span-1">
                        <label htmlFor="image" className="block text-sm font-medium text-gray-700">Image</label>
                        <input
                            type="file"
                            id="image"
                            name="image"
                            onChange={handleImageChange}
                            className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-300 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                            placeholder="Upload Image"
                        />
                    </div>

                    <div className="col-span-1">
                        <label htmlFor="off_type" className="block text-sm font-medium text-gray-700">Off Type</label>
                        <select
                            id="off_type"
                            name="off_type"
                            value={formData.off_type}
                            onChange={handleChange}
                            className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-300 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        >
                            <option value="" disabled>Select Off Type</option>
                            <option value="Percent">Percent</option>
                            <option value="Value">Value</option>
                        </select>
                    </div>

                    <div className="col-span-1">
                        <label htmlFor="off_value" className="block text-sm font-medium text-gray-700">Off Value</label>
                        <input
                            type="number"
                            id="off_value"
                            name="off_value"
                            value={formData.off_value}
                            onChange={handleChange}
                            className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-300 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                            placeholder="Enter Off Value"
                        />
                    </div>

                    <div className="col-span-1">
                        <label htmlFor="threshold" className="block text-sm font-medium text-gray-700">Threshold</label>
                        <input
                            type="number"
                            id="threshold"
                            name="threshold"
                            value={formData.threshold}
                            onChange={handleChange}
                            className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-300 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                            placeholder="Enter Threshold"
                        />
                    </div>

                    <div className="col-span-1">
                        <label htmlFor="frequency" className="block text-sm font-medium text-gray-700">Frequency</label>
                        <input
                            type="text"
                            id="frequency"
                            name="frequency"
                            value={formData.frequency}
                            onChange={handleChange}
                            className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-300 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                            placeholder="Enter Frequency"
                        />
                    </div>

                    <div className="col-span-1">
                        <label htmlFor="max_off" className="block text-sm font-medium text-gray-700">Max Off</label>
                        <input
                            type="number"
                            id="max_off"
                            name="max_off"
                            value={formData.max_off}
                            onChange={handleChange}
                            className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-300 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                            placeholder="Enter Max Off"
                        />
                    </div>

                    <div className="col-span-1">
                        <label htmlFor="start_date" className="block text-sm font-medium text-gray-700">Start Date</label>
                        <input
                            type="date"
                            id="start_date"
                            name="start_date"
                            value={formData.start_date}
                            onChange={handleChange}
                            className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-300 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                            placeholder="Select Start Date"
                        />
                    </div>

                    <div className="col-span-1">
                        <label htmlFor="end_date" className="block text-sm font-medium text-gray-700">End Date</label>
                        <input
                            type="date"
                            id="end_date"
                            name="end_date"
                            value={formData.end_date}
                            onChange={handleChange}
                            className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-300 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                            placeholder="Select End Date"
                        />
                    </div>
                    <div className="col-span-3">
                        <label htmlFor="description" className="block text-sm font-medium text-gray-700">Description</label>
                        <textarea
                            id="description"
                            name="description"
                            value={formData.description}
                            onChange={handleChange}
                            rows="4"
                            className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-300 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                            placeholder="Enter Description"
                        />
                    </div>
                    <div className="">
                        <button
                            type="submit"
                            className="w-full bg-blue-500 text-white font-bold py-3 px-4 rounded hover:bg-blue-600 focus:outline-none focus:shadow-outline"
                        >
                            {state ? "Update Coupon" : "Add Coupon"}
                        </button>
                    </div>
                </form>
            </section>
        </>
    );
}

export default AddCoupon;




