import React from 'react';
import '../assets/css/loader.css'; // Import the CSS file

const Loader = () => {
    return (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-400 bg-opacity-50 backdrop-blur-sm" style={{ zIndex: "9999" }}>
            <section className="loader">
                {[...Array(5)].map((_, i) => (
                    <div className="slider" style={{ '--i': i }} key={i}></div>
                ))}
            </section>
        </div>
    );
};

export default Loader;
