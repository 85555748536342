import React from 'react'

function TitleComp(props) {
    return (
        <>
            <h3 className='maintitle my-6 text-3xl font-semibold text-center headlandfont'>{props.title}</h3>
        </>
    )
}

export default TitleComp
