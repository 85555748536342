import React, { useEffect, useState } from 'react'
import { mastergetapi, superadmingetapi, superadminputapi } from '../../Api/Api'
import TableComp from '../../component/TableComp'
import Loader from '../../component/Loader';
import convertDateToMonthName from '../../component/FormDate';
import TitleComp from '../../component/TitleComp';
import { useNavigate } from 'react-router-dom';
import { Checkbox } from '@mui/material';

function User() {

    const [usertype, setusertype] = useState("");
    const [usertypedata, setUsertypedata] = useState([]);
    const [show, setshow] = useState(false)
    const [loading, setloading] = useState(false)
    const [data, setdata] = useState([])
    const token = localStorage.getItem("token")
    const [phonever, setphonever] = useState("")
    const [matchid, setmatchid] = useState("")
    const [isactive, setisactive] = useState("")
    const navigate = useNavigate()

    const [filterdata, setfilterdata] = useState([])

    let filterusertype = localStorage.getItem("filterusertype")

    useEffect(() => {
        if (filterusertype) {
            setusertype(filterusertype)
        }
    }, [filterusertype])

    const handleusertype = async () => {
        setloading(true);
        let res = await mastergetapi('user_type', token);
        if (!res.error) {
            setloading(false);
            setUsertypedata(res.data);
            if (!filterusertype) {
                localStorage.setItem("filterusertype", res.data[0]?._id)
            }
        }
    };

    useEffect(() => {
        handleusertype()
    }, [])

    const handleget = async () => {
        setloading(true)
        let res = await superadmingetapi(`details?all=1&user_type=${usertype}`, token)
        console.log(res)
        setdata(res.data)
        if (!res.error) {
            setloading(false)
        }
    }

    useEffect(() => {
        if (usertype) {
            handleget()
        }
    }, [usertype])

    const columns = [

        {
            field: "name",
            headerName: "Name",
            flex: 1,
        },
        {
            field: "phone",
            headerName: "Phone",
            flex: 1,
        },
        {
            field: "roles",
            headerName: "Roles",
            flex: 1,
            renderCell: (params) => {
                return (
                    <span>{params.row.roles.length} Access</span>
                )
            }
        },
        {
            field: "is_phone_verified",
            headerName: "Phone Verified",
            flex: 1,
            renderCell: (params) => {
                return (
                    <>

                        <button className='cutombtn' onClick={(e) => handlecheck(e, params.row._id, params.row.is_phone_verified, "is_phone_verified")} style={{ padding: "0" }}>
                            <Checkbox checked={params.row.is_phone_verified} />
                        </button>
                    </>
                )
            }
        },
        {
            field: "is_active",
            headerName: "Is Active",
            flex: 1,
            renderCell: (params) => {
                return (
                    <>
                        <button className='cutombtn' onClick={(e) => handlecheck(e, params.row._id, params.row.is_active, "is_active")} style={{ padding: "0" }}>
                            <Checkbox checked={params.row.is_active} />
                        </button>
                    </>
                )
            }
        },
        // {
        //     field: "created_at",
        //     headerName: "Created Date",
        //     flex: 1,
        //     renderCell: (params) => { // Corrected renderCell casing
        //         return <span>{convertDateToMonthName(params.row.created_at)}</span>;
        //     },
        // },
        {
            field: "info",
            headerName: "Info",
            flex: 1,
            renderCell: (params) => { // Corrected renderCell casing
                return <button className='cutombtn' onClick={(e) => handleuserinfo(e, params.row)}><i className="fa-solid fa-circle-info"></i></button>;
            },
        },
    ];










    if (matchid == "superadmin") {

        columns.push(
            {
                field: "Roles",
                headerName: "Roles",
                flex: 1,
                renderCell: (params) => {
                    return <button className='cutombtn' onClick={(e) => navigate(`/addpermission/vendor`, { state: params.row })}><i class="fa-solid fa-user-pen"></i></button>;
                },
            },

        );
    }
    if (matchid == "vendor") {

        columns.push(
            {
                field: "can_edit_bank",
                headerName: "Edit Bank / Request Bank Edit",
                flex: 2,
                renderCell: (params) => {
                    return (
                        <>
                            <div className="flex gap-3 tabletooltip">
                                <div className="uiverse">
                                    {/* <span className="tooltip1">Edit  Bank Request </span> */}
                                    <button className='cutombtn  tooltipname' style={{ padding: "0" }} onClick={(e) => setshow(true)}><Checkbox checked={params.row.request_edit_bank} /></button>

                                </div>
                                <div className="uiverse">
                                    {/* <span className="tooltip1">Edit Bank </span> */}
                                    <button className='cutombtn ' style={{ padding: "0" }} onClick={(e) => setshow(true)}><Checkbox checked={params.row.can_edit_bank} /></button>


                                </div>
                            </div>
                        </>
                    );
                },
            },
            {
                field: "can_edit_docs",
                headerName: "Edit Docuement /Request Document Edit",
                flex: 2,
                renderCell: (params) => {
                    return (
                        <>
                            <div className="flex gap-3">
                                <button className='cutombtn ' style={{ padding: "0" }} onClick={(e) => setshow(true)}><Checkbox checked={params.row.request_edit_docs} /></button>
                                <button className='cutombtn ' style={{ padding: "0" }} onClick={(e) => setshow(true)}><Checkbox checked={params.row.can_edit_docs} /></button>

                            </div>
                        </>
                    )
                },
            },
            {
                field: "store",
                headerName: "Stores",
                flex: 1,
                renderCell: (params) => {
                    return <button className='cutombtn' onClick={(e) => navigate(`/user/stores/${params.row._id}`)}><i className="fa-solid fa-store"></i></button>;
                },
            },

            {
                field: "Roles",
                headerName: "Roles",
                flex: 1,
                renderCell: (params) => {
                    return <button className='cutombtn' onClick={(e) => navigate(`/addpermission/vendor`, { state: params.row })}><i class="fa-solid fa-user-pen"></i></button>;
                },
            },
            {
                field: "product",
                headerName: "Product",
                flex: 1,
                renderCell: (params) => {
                    return <button className='cutombtn' onClick={(e) => navigate(`/user/product/${params.row._id}`, { state: "user" })}><i className="fa-solid fa-boxes-stacked"></i></button>;
                },
            }
        );
    }





    useEffect(() => {

        const result = usertypedata.filter((item) => item._id === usertype);

        // Check if there is at least one match
        if (result.length > 0 && result[0]?._id === usertype) {
            setmatchid(result[0]?.name)

        } else {
            console.log("No match found or result is empty");
        }
    }, [usertypedata, usertype]);







    const handleuserinfo = (e, item) => {
        e.preventDefault()
        navigate("/userinfo", { state: item })
    }


    const handlecheck = async (e, id, verify, paramtitle) => {
        e.preventDefault();
        setloading(true);

        let requestdata = {
            [paramtitle]: !verify  // Use computed property name for dynamic key
        };

        let res = await superadminputapi(`update?_id=${id}`, requestdata, token);

        if (!res.error) {
            setloading(false);
            handleget();
        } else {
            setloading(false);  // Ensure loading is stopped even if there's an error
            // Handle the error, e.g., show a toast notification
        }
    };




    useEffect(() => {
        if (isactive != "") {
            const filtered = data.filter((item) => item.is_active.toString() === isactive);
            setfilterdata(filtered)
        } else {
            setfilterdata(data)
        }

    }, [isactive, data]);


    const getRowId = (row) => row._id
    return (
        <>
            {loading && <Loader />}
            <section>
                <div className="container">
                    <div className="grid grid-cols-1">
                        <TitleComp title="Users" />
                    </div>
                    <div className="grid grid-cols-3 gap-3">
                        <div className="col-span-1 ">
                            <div className="">
                                <label htmlFor="type" className="block text-gray-700 text-sm font-medium mb-2">Filter According User Type</label>
                                <select name="type" id="type" className="block w-full mb-3 bg-gray-200 border border-gray-300 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500" value={usertype} onChange={(e) => { setusertype(e.target.value); localStorage.setItem("filterusertype", e.target.value) }}>
                                    <option value="" className='hidden' selected>Select User Type</option>
                                    {usertypedata.map((item) => (
                                        <option key={item._id} value={item._id}>{item.name}</option>
                                    ))}
                                </select>
                            </div>

                        </div>
                        {/* <div className="col-span-1 ">
                            <div className="">
                                <label htmlFor="type" className="block text-gray-700 text-sm font-medium mb-2">Filter According Phone Verified</label>
                                <select name="type" id="type" className="block w-full mb-3 bg-gray-200 border border-gray-300 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500" value={phonever} onChange={(e) => setphonever(e.target.value)}>
                                    <option value="">Select </option>
                                    <option value="true">True</option>
                                    <option value="false">False</option>

                                </select>
                            </div>

                        </div> */}
                        <div className="col-span-1 ">
                            <div className="">
                                <label htmlFor="type" className="block text-gray-700 text-sm font-medium mb-2">Filter According Is Active</label>
                                <select name="type" id="type" className="block w-full mb-3 bg-gray-200 border border-gray-300 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500" value={isactive} onChange={(e) => setisactive(e.target.value)}>
                                    <option value="">Select </option>
                                    <option value="true">True</option>
                                    <option value="false">False</option>

                                </select>
                            </div>

                        </div>
                    </div>
                    <div className="grid grid-cols-1">
                        <TableComp data={filterdata ?? data} getRowId={getRowId} columns={columns} />
                    </div>
                </div>
            </section>
        </>
    )
}

export default User
