


import React, { useEffect, useState } from 'react'
import { superadmingetapi, superadminputapi } from '../../Api/Api'
import notfound from "../../assets/images/notfound.avif"
import Loader from '../../component/Loader'
import { BASE_URL } from '../../Api/BaseUrl'
import { useNavigate, useParams } from 'react-router-dom'
import approve from "../../assets/images/approved.png"
import reject from "../../assets/images/rejected.png"
import store from "../../assets/images/store.jpg"
import convertDateToMonthName from '../../component/FormDate'
import Notfound from '../../component/Notfound'

function OrderHistory() {
    const navigate = useNavigate()
    let token = localStorage.getItem("token")
    const { id } = useParams()
    const [loading, setloading] = useState(false)
    const [data, setdata] = useState([])
    const [misc, setmisc] = useState([])
    const [datanotfound, setdatanotfound] = useState(false)

    const handleget = async () => {
        setloading(true);
        try {
            let res = await superadmingetapi(`fetch_store?vendor=${id}`, token);

            setdata(res.data?.[0]);
            setmisc(res.misc)
            if (res.data.error) {
                setdatanotfound(true)
            }

        } catch (error) {
            console.error('Error fetching orders:', error);

        } finally {
            setloading(false);
        }
    };

    useEffect(() => {
        handleget()
    }, [])

    const handleCheckboxChange = async (itemId, status) => {
        setloading(true);
        let requestdata = {
            _id: itemId,
            verified: !status
        }
        let res = await superadminputapi(`update_store`, requestdata, token);

        if (!res.error) {
            setloading(false)
            handleget()
        }
        setloading(false);
    };

    return (
        <>
            {loading && <Loader />}
            <section className=''>
                <img
                    className="w-full h-[350px] object-cover rounded-b-3xl shadow-lg"
                    src={store}
                    alt="Shop with We Are Open sign"
                />
            </section>

            {!datanotfound ? <section className='px-8 py-12 relative top-[-100px]'>
                <div className="container mx-auto">
                    <div className="grid grid-cols-1 lg:grid-cols-4 gap-8">
                        <div className="col-span-4 ">
                            <div className="bg-white shadow-lg rounded-2xl p-6">
                                <div className="grid grid-cols-1 md:grid-cols-3 gap-10">
                                    <div className="col-span-1">
                                        <img
                                            className='rounded-xl shadow-md w-full h-auto'
                                            src={`${BASE_URL}/${data?.image?.file}`}
                                            onError={(e) => e.target.src = notfound}
                                            alt=""
                                        />
                                    </div>
                                    <div className="col-span-2">
                                        <div className="p-3 storebox">
                                            <div className="flex items-center gap-4">
                                                <h3 className='text-4xl text-orange-600 font-bold'>{data?.name}</h3>
                                                <label htmlFor="" className="bg-white">
                                                    <div className="checkbox-wrapper-19">
                                                        <input
                                                            id={`cbtest-19-${data?._id}`}
                                                            type="checkbox"
                                                            checked={data?.verified}
                                                            onChange={() => handleCheckboxChange(data?._id, data?.verified)}
                                                        />
                                                        <label className="check-box" htmlFor={`cbtest-19-${data?._id}`}></label>
                                                    </div>
                                                </label>
                                            </div>

                                            <div className="flex flex-wrap gap-2 items-center mt-4">
                                                <h5 className='text-gray-500'>Self Delivery:</h5>
                                                <img src={data?.self_delivery ? approve : reject} className='h-[40px]' alt="" />
                                            </div>

                                            <div className="flex flex-wrap gap-2 items-center mt-4">
                                                <h5 className='text-gray-500'>Address:</h5>
                                                <span className="text-lg text-gray-700">{data?.city}, {data?.state}, {data?.pincode}, {data?.address}</span>
                                            </div>

                                            <div className="flex flex-wrap gap-2 items-center mt-4">
                                                <h5 className='text-gray-500'>Connected App Category:</h5>
                                                <div className="flex flex-wrap gap-2">
                                                    {data?.connected_app_category?.map((item) => (
                                                        <>
                                                            <span className="text-blue-500">
                                                                <i className="fa-solid fa-circle"></i>
                                                            </span>  <h6 className='font-bold text-blue-500'>{item?.name}</h6>
                                                        </>
                                                    ))}
                                                </div>
                                            </div>

                                            <div className="flex flex-wrap gap-2 items-center mt-4">
                                                <h5 className='text-gray-500'>Minimum Order Value:</h5>
                                                <h6 className="text-lg text-gray-700">{data?.minimum_order_value}</h6>
                                            </div>

                                            <div className="flex flex-wrap gap-2 items-center mt-4">
                                                <h5 className='text-gray-500'>Radius:</h5>
                                                <h6 className="text-lg text-gray-700">{data?.radius} km</h6>
                                            </div>

                                            <div className="flex flex-wrap gap-10 w-full items-center mt-4">
                                                <div className="">
                                                    <h5 className='text-gray-500'>Start Time:</h5>
                                                    <h6 className="text-lg text-gray-700">{data?.start_time}</h6>
                                                </div>
                                                <div className="">
                                                    <h5 className='text-gray-500'>Close Time:</h5>
                                                    <h6 className="text-lg text-gray-700">{data?.close_time}</h6>
                                                </div>
                                            </div>



                                            <div className="flex flex-wrap gap-2 items-center mt-4">
                                                <h5 className='text-gray-500'>Subscription:</h5>
                                                <img src={misc?.subscription ? approve : reject} className='h-[40px]' alt="" />
                                            </div>

                                            <p className='bg-gray-100 w-max px-4 py-2 mt-4 rounded-lg shadow-sm'>
                                                <span className='font-semibold'>Store Created on:</span> {convertDateToMonthName(data?.created_at)}
                                            </p>

                                            <div className="mt-6">
                                                <div className={`flex gap-6`}>
                                                    {data?.user?.name && (
                                                        <div className="uiverse">
                                                            <span className="tooltip1">User</span>
                                                            <span className='tooltipname'>
                                                                <i className="fa-solid fa-user"></i> {data?.user?.name}
                                                            </span>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section> :
                <>
                    <div className=" storeboxnotfound px-8 relative top-[-50px]">
                        <Notfound title="Store Not Found" />
                    </div>
                </>
            }
        </>
    )
}

export default OrderHistory
