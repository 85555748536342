




import { useState, useEffect } from "react";
import { Link, NavLink, useLocation, useParams } from "react-router-dom";

import { ToastContainer } from "react-toastify";
import '../assets/css/sidebar.css'

import shortlogo from "../assets/images/logo.png";
import logo from "../assets/images/logo.png";


// eslint-disable-next-line react/prop-types
const Sidebar = ({ content }) => {
    // const status = localStorage.getItem("status");

    let username = localStorage.getItem("username")
    const location = useLocation()

    const { id } = useParams()

    const userinfo = location.pathname == `/user/stores/${id}`


    const [isCollapsed, setIsCollapsed] = useState(
        localStorage.getItem("isCollapsed") === "true"
    );

    // const navigate = useNavigate();

    const handleToggleSidebar = (e) => {
        e.preventDefault();
        setIsCollapsed(!isCollapsed);
        localStorage.setItem("isCollapsed", isCollapsed ? "false" : "true");
    };

    useEffect(() => {
        checkCollapse();
    }, []);

    const checkCollapse = () => {
        if (localStorage.getItem("isCollapsed") === "true") {
            setIsCollapsed(true);
        }
    };

    const handleLogout = () => {
        localStorage.clear();

    };




    const menuData = [

        { name: "Dashboard", icon: <i className="fa-solid fa-house"></i>, link: "/dashboard", type: "all" },
        { name: "User", icon: <i class="fa-solid fa-user"></i>, link: "/user", type: "all" },
        { name: "Order History", icon: <i class="fa-solid fa-clock-rotate-left"></i>, link: "/orderhistory", type: "all" },

        { name: "User Type", icon: <i class="fa-solid fa-users"></i>, link: "/usertype", type: "superadmin" },
        { name: "Permission", icon: <i class="fa-solid fa-key"></i>, link: "/permission", type: "superadmin" },
        { name: "Modules", icon: <i class="fa-solid fa-boxes-stacked"></i>, link: "/modules", type: "superadmin" },
        // { name: "Settings", icon: <i class="fa-solid fa-gear"></i>, link: "/setting", type: "superadmin" },
        { name: "Positions", icon: <i class="fa-solid fa-chart-line"></i>, link: "/positions", type: "superadmin" },
        { name: "App Category", icon: <i class="fa-solid fa-list"></i>, link: "/appcategory", type: "superadmin" },
        { name: "Document Type", icon: <i class="fa-solid fa-file"></i>, link: "/documenttype", type: "superadmin" },
        { name: "Document Name", icon: <i class="fa-solid fa-book"></i>, link: "/documentname", type: "superadmin" },
        { name: "Brands", icon: <i class="fa-solid fa-b"></i>, link: "/brand", type: "superadmin" },
        { name: "Units", icon: <i class="fa-brands fa-unity"></i>, link: "/units", type: "superadmin" },
        { name: "Coupons", icon: <i class="fa-solid fa-ticket"></i>, link: "/coupon", type: "superadmin" },
        { name: "Coupons List", icon: <i class="fa-solid fa-money-bill"></i>, link: "/couponlist", type: "superadmin" },
        { name: "Subscription", icon: <i class="fa-solid fa-indian-rupee-sign"></i>, link: "/subscription", type: "superadmin" },
        { name: "Slider", icon: <i class="fa-solid fa-sliders"></i>, link: "/slider", type: "superadmin" },
        { name: "Pages", icon: <i class="fa-regular fa-file-lines"></i>, link: "/pages", type: "superadmin" },



        // {
        //     name: "Blog Section", type: "blog", icon: <i className="fa-brands fa-blogger-b"></i>, link: '/subssservice', subMenu: [
        //         { name: "Blog Post", icon: <i className="fa-solid fa-briefcase"></i>, link: "/blogs" },
        //         { name: "Blog List", icon: <i className="fa-solid fa-briefcase"></i>, link: "/bloglist" },
        //     ]
        // },

        { name: "Logout", icon: <i className="fa-solid fa-right-from-bracket"></i>, link: "/login", type: "all", isLogout: true },
    ];

    const [openDropdown, setOpenDropdown] = useState('');

    const toggleDropdown = (e, itemName) => {
        e.preventDefault();
        setOpenDropdown(openDropdown === itemName ? "" : itemName);
    };

    const scrollToTop = () => {
        window.scrollTo(0, 0);
    };

    useEffect(() => {
        scrollToTop()
    }, [])








    return (
        <div className="flex w-full">


            <ToastContainer />
            <div className={`sidebarContainer ${isCollapsed ? "collapsed" : ""}`}>
                <div className="w-full h-full relative">
                    <div className="sidebar-header flex justify-center">
                        <Link
                            className="btn text-dark bg-white"
                            id="closeSidebar"
                            onClick={(e) => handleToggleSidebar(e)}
                        >
                            {isCollapsed ? (
                                <i className="fa-solid fa-xmark"></i>
                            ) : (
                                <i className="fa-solid fa-bars"></i>
                            )}
                        </Link>
                        <Link className="hidden md:block">
                            {isCollapsed ? (
                                <img
                                    src={shortlogo}
                                    alt=""
                                    className="img-fluid mt-1"
                                    style={{ width: "130px" }}
                                />
                            ) : (
                                <img src={logo} style={{ width: "45px" }} alt="" className="img-fluid beat_logo mt-1" />
                            )}
                        </Link>
                    </div>
                    <div className="sidebar-content" id="Sidebar_nav">

                        <ul className="list-none">
                            {menuData.map((item, index) => (
                                <li key={index} className={`${item.type == username || item.type == "all" ? "" : "hidden"}`}>
                                    {item.subMenu ? (
                                        <div className={`${item.type}`}>
                                            <NavLink
                                                to={item.link}

                                                className={`flex items-center gap-2 Sidebar_link  `}
                                                onClick={(e) => toggleDropdown(e, item.name)}
                                            >
                                                <span className="icon">{item.icon}</span>
                                                <p className={`tool-tip ${!isCollapsed ? "" : "nonetooltip"}`}>
                                                    {item.name}

                                                </p>
                                            </NavLink>
                                            {openDropdown === item.name && (
                                                <ul className="list-none" style={{ paddingLeft: "20px" }}> {/* Adjust padding as needed */}
                                                    {item.subMenu.map((subItem, subIndex) => (
                                                        <li key={subIndex} className={`${item.type}`}>
                                                            <NavLink
                                                                to={subItem.link}
                                                                className="flex items-center gap-2 Sidebar_link"
                                                                onClick={(e) => item.isLogout && handleLogout(e)}
                                                            >
                                                                <span className="icon"><i className="fa-solid fa-circle-dot"></i></span>
                                                                <p className={`tool-tip ${!isCollapsed ? "" : "nonetooltip"}`}>
                                                                    {subItem.name}
                                                                </p>
                                                            </NavLink>
                                                        </li>
                                                    ))}
                                                </ul>
                                            )}
                                        </div>
                                    ) : (
                                        <NavLink
                                            to={item.link}
                                            className={`flex items-center gap-2 Sidebar_link  `}
                                            onClick={(e) => item.isLogout && handleLogout(e)}
                                        >
                                            <span className={`icon ${item.isLogout ? "bg-dark text-white" : ""}`}>{item.icon}</span>
                                            <p className={`tool-tip ${!isCollapsed ? "" : "nonetooltip"}`}>
                                                {item.name}

                                            </p>
                                        </NavLink>
                                    )}
                                </li>
                            ))}




                        </ul>
                    </div>
                    {/* <div className="sidebar-footer">

                        <div >
                            <div className="sidebar-content">
                                <ul>
                                    <li>
                                        <NavLink
                                            to={"/login"}
                                            className={`flex items-center gap-2 Sidebar_link `}
                                            onClick={(e) => handleLogout(e)}
                                        >
                                            <span className="icon"><i className="fa-solid fa-right-from-bracket"></i></span>
                                            <p className={`tool-tip ${!isCollapsed ? "" : "nonetooltip"}`}>
                                                Logout

                                            </p>
                                        </NavLink>
                                    </li>
                                </ul>
                            </div>

                        </div>
                    </div> */}
                </div>
            </div>
            <div className={`mainContainer ${isCollapsed ? "collapsed" : ""}`}>
                <div className="w-full h-full e">
                    <div className="w-full sticky top-0 ">
                        <nav className="w-full topbarNav bg-nav bg-white  py-3 z-20 relative">
                            <div className="container mx-auto">
                                <div className="flex w-full justify-between items-center">
                                    <div className="px-5">
                                        <Link
                                            className="btn border-0 shadow-none makeExtend bg-white "
                                            onClick={handleToggleSidebar}
                                        >
                                            {isCollapsed ? (
                                                <i className="fa-solid fa-bars"></i>
                                            ) : (
                                                <i className="fa-solid fa-xmark"></i>
                                            )}
                                        </Link>
                                        <div className="mobilelogo">
                                            <Link className="block md:hidden">
                                                <img
                                                    src={logo}
                                                    className="img-fluid mobile_fullname_logo"
                                                    alt=""
                                                />
                                            </Link>
                                            <Link className="hidden md:block">
                                                <img
                                                    src={logo}
                                                    alt=""
                                                    className="img-fluid beat_logo"
                                                />
                                                <img
                                                    src={logo}
                                                    className="img-fluid fullname_logo"
                                                    alt=""
                                                />
                                            </Link>
                                        </div>
                                    </div>
                                    <h3 className="text-end bg-blue-500 me-5 py-1 rounded-md text-white px-5">{username}</h3>
                                </div>

                            </div>
                        </nav>
                        <div className={`container mx-auto ${userinfo ? "p-0" : "p-3"}`}>
                            <div className={`grid gap-3 ${userinfo ? "mt-0" : "mt-3"}`}>{content}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    );
};

export default Sidebar;

