function convertDateToMonthName(dateString) {

    const options = { year: "numeric", month: "long", day: "numeric" };
    const date = new Date(dateString);
    // Adjust the options to UTC
    options.timeZone = 'UTC';
    return date.toLocaleDateString("en-US", options);
}

export default convertDateToMonthName;
