import React from 'react'
import GetPage from '../component/GetPage'


function Modules() {
    return (
        <>

            <GetPage title="Modules" url="modules" />
        </>
    )
}

export default Modules
