import React, { useEffect, useState } from 'react';
import TitleComp from '../../component/TitleComp';
import TableComp from '../../component/TableComp';
import { masterdeleteapi, mastergetapi, masterpostapi, masterputapi } from '../../Api/Api';
import convertDateToMonthName from '../../component/FormDate';
import { toast } from 'react-toastify';

import Loader from '../../component/Loader';
import { BASE_URL } from '../../Api/BaseUrl';
import axios from 'axios';


function Slider() {

    const [data, setdata] = useState([])
    let token = localStorage.getItem("token")

    const [type, settype] = useState("")
    const [parentcategorydata, setparentcategorydata] = useState([])
    const [parentcategory, setparentcategory] = useState("")
    const [childcategory, setchildcategory] = useState("")


    const [childcategorydata, setchildcategorydata] = useState([])
    const [loading, setloading] = useState(false)
    const [editid, seteditid] = useState("")
    const [image, setimage] = useState("")
    const [editimage, seteditimage] = useState("")


    const handlePermission = async () => {
        setloading(true)
        let res = await mastergetapi("app_category", token)
        console.log(res)

        let filteredData = res?.data?.filter(item => !item.parent);

        if (!res.error) {
            setparentcategorydata(filteredData)
            setloading(false)
        } else {
            setloading(false)
        }
    }

    const handlechildcategory = async () => {
        setloading(true)
        let res = await mastergetapi(`app_category?parent=${parentcategory}`, token)




        if (!res.error) {
            setchildcategorydata(res.data)
            setloading(false)
        } else {
            setloading(false)
        }

    }

    useEffect(() => {
        handlechildcategory()
    }, [parentcategory])

    useEffect(() => {
        handlePermission()
    }, [])

    const getRowId = (row) => row._id
    const columns = [
        {
            field: "image",
            headerName: "Image",
            flex: 1,
            renderCell: (params) => {
                return <img style={{ height: "60px", width: "60px", objectFit: "contain", borderRadius: "50%" }} src={`${BASE_URL}${params.row.image.file}`}></img>
            }
        },
        {
            field: "type",
            headerName: "Type",
            flex: 1,
        },
        {
            field: "app_category",
            headerName: "App Category",
            flex: 1,
            renderCell: (params) => {
                return params.row.app_category.name
            }
        },

        {
            field: "created_at",
            headerName: "Created Date",
            flex: 1,
            renderCell: (params) => { // Corrected renderCell casing
                return <span>{convertDateToMonthName(params.row.created_at)}</span>;
            },
        },

        {
            field: "edit",
            headerName: "Edit",
            flex: 1,
            renderCell: (params) => { // Corrected renderCell casing
                return <button className='cutombtn' onClick={(e) => handleEdit(e, params.row)} ><i class="fa-solid fa-pen-to-square"></i></button>;
            },
        },
        {
            field: "delete",
            headerName: "Delete",
            flex: 1,
            renderCell: (params) => { // Corrected renderCell casing
                return <button className='cutombtn' onClick={(e) => handledelete(e, params.row._id)}><i class="fa-solid fa-trash"></i></button>;
            },
        },

    ];


    const handleEdit = (e, item) => {
        e.preventDefault();
        settype(item.type);
        seteditimage(item.image);
        seteditid(item._id);
        setparentcategory(item.app_category.parent)
        setchildcategory(item.app_category._id)
    };

    const handleslider = async () => {
        let res = await mastergetapi('sliders', token)
        console.log(res.data)
        setdata(res.data)
    }

    const handleimage = (e) => {
        e.preventDefault()
        setimage(e.target.files[0])
        console.log(e.target.files[0])
    }

    useEffect(() => {
        handleslider()
    }, [])


    const handlesubmit = async (e) => {
        setloading(true);
        e.preventDefault();

        let formdata = new FormData();
        formdata.append("type", type);
        formdata.append("image", image);
        formdata.append("app_category", childcategory)

        let res = "";
        if (editid) {
            formdata.append("_id", editid);  // Append the edit ID to form data
            res = await masterputapi("sliders", formdata, token, true);  // Ensure you're sending form data correctly
        } else {
            res = await masterpostapi("sliders", formdata, token, true);  // Ensure you're sending form data correctly
        }

        setloading(false);
        handleslider();
        settype("");
        setimage("");
        toast.success(`${editid ? "Data Updated Successfully" : "Data Submitted Successfully"}`);

        seteditid("");
    };







    const headers = {
        Authorization: `Bearer ${token}`
    };

    const handledelete = async (e, id) => {
        e.preventDefault();

        let requestData = {
            _id: id,
        };

        try {
            const response = await axios.delete(`${BASE_URL}master/sliders`, {
                headers,
                data: requestData,  // Correct way to send data in a DELETE request
            });

            if (!response.error) {
                toast.success("Slider deleted successfully");
                handleslider();
            } else {
                toast.error("Failed to delete Slider. Please try again.");
            }
        } catch (error) {
            toast.error("Failed to delete Slider. Please try again.");
            console.error("Error deleting slider:", error);
        }
    };

    return (
        <>
            {loading && <Loader />}
            <section className="py-8">
                <div className="container mx-auto px-4">
                    <form onSubmit={handlesubmit}>
                        <div className="grid grid-cols-3 gap-2">
                            <div className="col-span-3">
                                <TitleComp title="Slider" />
                            </div>
                            <div className="col-span-3">
                                {editid &&

                                    <img style={{ height: "150px", width: "150px", objectFit: "cover", borderRadius: "10px" }} src={`${BASE_URL}${editimage.file}`} alt="" />

                                }
                            </div>
                            <div className="col-span-1">
                                <label htmlFor="slider-type" className="block text-gray-700 text-sm font-bold mb-2">
                                    Slider Type
                                </label>
                                <div className="relative">
                                    <select
                                        id="slider-type"
                                        value={type}
                                        onChange={(e) => settype(e.target.value)}
                                        className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-300 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                    >
                                        <option value="" className='d-none'>Select Slider Type</option>
                                        <option value="HOMESLIDER" >Home Slider</option>
                                        <option value="INTROSLIDER" >Intro Slider</option>
                                        <option value="LOGINPAGEBG" >Login Page Background</option>


                                    </select>

                                </div>
                            </div>

                            <div className="col-span-1">
                                <label htmlFor="image-upload" className="block text-gray-700 text-sm font-bold mb-2">
                                    Image
                                </label>
                                <div className="relative">
                                    <input
                                        type="file"
                                        required
                                        id="image-upload"
                                        onChange={handleimage}
                                        className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-300 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                    />
                                </div>
                            </div>
                            <div className="col-span-1">
                                <label htmlFor="slider-type" className="block text-gray-700 text-sm font-bold mb-2">
                                    Parent Category
                                </label>
                                <div className="relative">
                                    <select
                                        id="slider-type"
                                        value={parentcategory}

                                        onChange={(e) => setparentcategory(e.target.value)}
                                        className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-300 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                    >
                                        <option value="" className='d-none'>Select Parent Category</option>
                                        {parentcategorydata.map((item) => {
                                            return (
                                                <>
                                                    <option value={item._id} >{item.name}</option>

                                                </>
                                            )
                                        })}


                                    </select>

                                </div>
                            </div>
                            {parentcategory && <div className="col-span-1">
                                <label htmlFor="slider-type" className="block text-gray-700 text-sm font-bold mb-2">
                                    Category
                                </label>
                                <div className="relative">
                                    <select
                                        id="slider-type"
                                        value={childcategory}

                                        onChange={(e) => setchildcategory(e.target.value)}
                                        className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-300 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                    >
                                        <option value="" className='d-none'>Select Parent Category</option>
                                        {childcategorydata.map((item) => {
                                            return (
                                                <>
                                                    <option value={item._id} >{item.name}</option>

                                                </>
                                            )
                                        })}


                                    </select>

                                </div>
                            </div>}
                            <div className="col-span-1 mt-7">
                                <button
                                    type="submit"
                                    className=" px-5 bg-blue-500 text-white font-bold py-3 px-4 rounded hover:bg-blue-600 focus:outline-none focus:shadow-outline"
                                >
                                    Add Slider
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </section>
            <section>
                <div className="container">
                    <div className="grid grid-cols-1">

                        <div className="cols-span-1">
                            <TableComp data={data} columns={columns} getRowId={getRowId} />
                        </div>
                    </div>
                </div>
            </section>
        </>


    );
}

export default Slider;
