import React, { useEffect, useState } from 'react'
import TitleComp from './TitleComp'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { mastergetapi, masterpostapi, masterputapi } from '../Api/Api'
import Loader from './Loader'
import { toast } from 'react-toastify'

function AddPage() {
    const { url, title } = useParams()
    const { state } = useLocation()
    const [name, setname] = useState("")
    const [loading, setloading] = useState(false)
    const [selectcat, setselectcat] = useState("")
    const [catdata, setcatdata] = useState([])
    let token = localStorage.getItem("token")
    const navigate = useNavigate()
    const location = useLocation()

    let editpage = location.pathname == `/editpage/${url}/${title}`
    let childpage = location.pathname == `/editpage/${url}/${title}/parent`
    let childaddpage = location.pathname == `/addpage/${url}/${title}/parent`




    const formattedTitle = title.replace(/_/g, ' ');



    const handleedit = () => {
        setname(state.name)
        setselectcat(state?.parent?._id)
    }


    const handlePermission = async () => {
        setloading(true)
        let res = await mastergetapi("app_category", token)
        console.log(res)

        let filteredData = res?.data?.filter(item => !item.parent);

        if (!res.error) {
            setcatdata(filteredData)
            setloading(false)
        } else {
            setloading(false)
        }

    }

    useEffect(() => {
        if (childaddpage) {
            handlePermission()
        }
    }, [])


    useEffect(() => {
        if (editpage || childpage) {

            handleedit()
            handlePermission()

        }
    }, [state])

    const handlesubmit = async (e) => {
        e.preventDefault()
        setloading(true)
        let requestdata = {
            name: name
        }
        let res = ""

        if (editpage || childpage) {
            requestdata._id = state._id
            if (childpage) {
                requestdata.parent = selectcat
            }
            res = await masterputapi(`${url}`, requestdata, token)
        } else {
            if (childaddpage) {
                requestdata.parent = selectcat
            }
            res = await masterpostapi(`${url}`, requestdata, token)
        }
        console.log(res)
        if (!res.error) {
            setloading(false)
            toast.success("Data Submit successfully")
            navigate(-1)

        } else {
            setloading(false)
        }


    }
    return (
        <>
            {loading && <Loader />}
            <section>
                <div className="container">
                    <div className="grid grid-cols-1">
                        <div className="cols-span-1">
                            <TitleComp title={`${editpage || childpage ? "Edit" : "Add"} ${formattedTitle}`} />
                        </div>
                        <div className="">
                            <div className="bg-white p-6 rounded-lg shadow-lg  w-full ">

                                <form className="grid grid-cols-3 gap-4 align-middle" onSubmit={handlesubmit}>
                                    <div className=" cols-span-1">
                                        <label htmlFor="name" className="block text-gray-700 text-sm font-medium mb-2">Name</label>
                                        <input
                                            type="text"
                                            id="name"
                                            value={name}
                                            onChange={(e) => setname(e.target.value)}
                                            className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-300 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                            placeholder="Enter Name"
                                        />
                                    </div>
                                    {(childpage || childaddpage) && <div className=" cols-span-1">
                                        <label htmlFor="name" className="block text-gray-700 text-sm font-medium mb-2"> Parent Category</label>
                                        <select name="type" id="type" className="block w-full bg-gray-200 border border-gray-300 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500" value={selectcat} onChange={(e) => setselectcat(e.target.value)}>
                                            <option value="" className='hidden' selected>Select Parent Category</option>
                                            {catdata.map((item) => (
                                                <option key={item._id} value={item._id}>{item.name}</option>
                                            ))}
                                        </select>
                                    </div>}
                                    <div className=' cols-span-1 '>
                                        <button
                                            type="submit"
                                            className=" mt-7 bg-blue-500 w-50 hover:bg-blue-600 text-white font-semibold py-2 px-5 rounded focus:outline-none focus:ring-2 focus:ring-blue-400"
                                        >
                                            {editpage || childpage ? "Update" : "Submit"}
                                        </button>
                                    </div>
                                </form>

                            </div>
                        </div>

                    </div>
                </div>
            </section>
        </>
    )
}

export default AddPage
