import { Box } from '@mui/material'
import { DataGrid } from '@mui/x-data-grid'
import React from 'react'

function TableComp(props) {

    return (
        <>
            <div className='tablebox'>
                <Box>
                    <DataGrid
                        rows={props.data}
                        columns={props.columns}
                        getRowId={props.getRowId}
                    />
                </Box>
            </div>
        </>
    )
}

export default TableComp
