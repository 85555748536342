import React, { useRef } from 'react';

import { BASE_URL } from '../../Api/BaseUrl';
import html2pdf from "html2pdf.js";
import logo from "../../assets/images/logo.png"; // Adjust the path as needed
import { useReactToPrint } from "react-to-print";
const Invoice = ({ state, data, misc }) => {

    console.log(misc)




    // Function to print the content
    const tableRef = useRef();

    const handlePrint = useReactToPrint({
        content: () => tableRef.current,

    });

    const handleDownload = () => {
        const content = tableRef.current;

        const pdfOptions = {
            margin: 10,
            filename: "invoice.pdf",
            image: { type: "jpeg", quality: 0.98 },
            html2canvas: { scale: 1 },
            jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
        };

        // Apply styles to the PDF content
        content.insertAdjacentHTML("beforeend", `<style>${pdfStyles}</style>`);

        html2pdf(content, pdfOptions);
    };

    // Add explicit styles
    const pdfStyles = `
.tableview th, .tableview td {
    padding: 10px; // Add the desired padding
}
// Add more styles as needed
`;

    return (
        <section className="p-4">
            <div className="container mx-auto">
                <div className="grid grid-cols-3 gap-3">
                    <div className="col-span-3">
                        <div className="p-4 bg-white shadow rounded-lg">
                            <div className="flex items-center justify-between mb-4">
                                <h4 className="text-xl font-semibold">Recent Orders</h4>
                                <div className="flex space-x-2">
                                    <button
                                        onClick={handleDownload}
                                        className="inline-flex items-center px-4 py-2 text-sm font-medium text-white bg-blue-600 rounded-lg hover:bg-blue-700"
                                    >
                                        <i className="bx bx-download mr-1"></i>Download Invoice
                                    </button>
                                    <button
                                        onClick={handlePrint}
                                        className="inline-flex items-center px-4 py-2 text-sm font-medium text-white bg-green-600 rounded-lg hover:bg-green-700"
                                    >
                                        <i className="bx bx-printer mr-1"></i>Print Invoice
                                    </button>
                                </div>
                            </div>



                            <div className=" p-4 bg-white" ref={tableRef}>

                                <header>
                                    <div className="flex flex-col sm:flex-row items-center justify-between gap-y-3">
                                        <div className="text-center sm:text-left">
                                            <img id="logo" src={logo} title="Koice" className='w-14' alt="Storo" />
                                        </div>
                                        <div className="text-center sm:text-right">
                                            <h4 className="text-xl mb-0">Invoice</h4>
                                        </div>
                                    </div>
                                    <hr className="my-4" />
                                </header>


                                <main>
                                    <div className="flex flex-col sm:flex-row justify-between">
                                        <div className="sm:w-1/2">
                                            <strong>Date:</strong> {new Date().toLocaleDateString()}

                                        </div>
                                        <div className="sm:w-1/2 text-right">
                                            <strong>Invoice No:</strong> #{state.sub_orders._id.substring(10, 24)}
                                        </div>
                                    </div>
                                    <hr className="my-4" />
                                    <div className="flex flex-col sm:flex-row justify-between mb-3">
                                        <div className="sm:w-1/2 sm:order-2 text-right">
                                            <strong>Pay To:</strong>

                                            <address className="not-italic">
                                                <address className="not-italic">
                                                    <p>{state.sub_orders.user_address.name}</p>
                                                    <p>{state.sub_orders.user_address.phone}</p>
                                                    <p>{state.sub_orders.user_address.address}</p>
                                                    <p>{state.sub_orders.user_address.city}, {state.sub_orders.user_address.state}</p>
                                                    <p>{state.sub_orders.user_address.pincode}</p>
                                                    <p>{state.sub_orders.user_address.country}</p>
                                                </address>

                                            </address>


                                        </div>
                                        <div className="sm:w-1/2 sm:order-1">
                                            <strong>Invoiced To:</strong>
                                            <address className="not-italic">
                                                {/* <p>{state.store.name}</p>
                                                */}


                                                <p>{state.store.name}</p>
                                                <p>{state.vendor.phone}</p>
                                                <p>{state.store.address}</p>
                                                <p>{state.store.city}, {state.store.state}</p>
                                                <p>{state.store.pincode}</p>
                                                <p>{state.store.country}</p>

                                            </address>
                                        </div>

                                    </div>


                                    <div className="overflow-x-auto">
                                        <table className="w-full border  mt-3">
                                            <thead>

                                                <tr className='bg-gray-100'>
                                                    <th className="px-4 py-2 text-left text-sm font-medium text-gray-700">Item</th>
                                                    <th className="px-4 py-2 text-center text-sm font-medium text-gray-700">Price</th>
                                                    <th className="px-4 py-2 text-center text-sm font-medium text-gray-700">Quantity</th>
                                                    <th className="px-4 py-2 text-right text-sm font-medium text-gray-700">Totals</th>
                                                </tr>
                                            </thead>
                                            <tbody>

                                                {data.map((item) => {
                                                    return (
                                                        <>
                                                            <tr>
                                                                <td className="px-4 py-2 border">{item.product.name}</td>
                                                                <td className="px-4 py-2 text-center border">₹ {item.unit_selling_price}</td>
                                                                <td className="px-4 py-2 text-center border">{item.qty}</td>
                                                                <td className="px-4 py-2 text-right border"> ₹ {item.selling_price}</td>
                                                            </tr>
                                                        </>
                                                    )
                                                })}

                                            </tbody>
                                        </table>
                                    </div>

                                    <div className="overflow-x-auto">
                                        <table className="w-full border-t mb-4 ">
                                            <tbody>
                                                <tr className="bg-gray-100 ">
                                                    <td className="p-2 text-right border">
                                                        <strong>Sub Total:</strong>
                                                    </td>
                                                    <td className="p-2 w-1/4 text-right border">₹ {misc.totalprice}</td>
                                                </tr>
                                                <tr className="bg-gray-100 ">
                                                    <td className="p-2 text-right border">
                                                        <strong>Tax:</strong>
                                                    </td>
                                                    <td className="p-2 w-1/4 text-right border">₹ {misc.tax}</td>
                                                </tr>
                                                <tr className="bg-gray-100 ">
                                                    <td className="p-2 text-right border">
                                                        <strong>Total:</strong>
                                                    </td>
                                                    <td className="p-2 w-1/4 text-right border">₹ {misc.priceaftertax}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </main>


                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Invoice;









