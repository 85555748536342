import React, { useEffect, useState } from 'react';
import ApexChartComponent from '../component/ApexChart';
import { superadmingetapi } from '../Api/Api';
import PerformanceChart from '../component/PerformanceChart';
import Loader from '../component/Loader';
import DashboardComp from '../component/DashboardComp';
import ApexChart from '../component/ApexChart';


function Dashboard() {
    // Example data
    let token = localStorage.getItem("token")
    const [data, setdata] = useState([])
    const salesData = [34, 44, 54, 21, 12, 43, 33, 23, 66, 66, 58];
    const categories = ["2021-09-19T00:00:00", "2021-09-19T01:30:00", "2021-09-19T02:30:00", "2021-09-19T03:30:00", "2021-09-19T04:30:00", "2021-09-19T05:30:00", "2021-09-19T06:30:00"];


    const dashdata = [
        {
            type: "Total Sales",
            amount: "34,945",
            bg: <svg xmlns="http://www.w3.org/2000/svg" width="48" height="52" viewBox="0 0 48 52" fill="none"><path d="M19.1094 2.12943C22.2034 0.343099 26.0154 0.343099 29.1094 2.12943L42.4921 9.85592C45.5861 11.6423 47.4921 14.9435 47.4921 18.5162V33.9692C47.4921 37.5418 45.5861 40.8431 42.4921 42.6294L29.1094 50.3559C26.0154 52.1423 22.2034 52.1423 19.1094 50.3559L5.72669 42.6294C2.63268 40.8431 0.726688 37.5418 0.726688 33.9692V18.5162C0.726688 14.9435 2.63268 11.6423 5.72669 9.85592L19.1094 2.12943Z" fill="#22C55E"></path></svg>,
            icon: <i class="fa-solid fa-bag-shopping "></i>
        },

        {
            type: "Total  Income",
            amount: "37,802",
            bg: <svg xmlns="http://www.w3.org/2000/svg" width="48" height="52" viewBox="0 0 48 52" fill="none"><path d="M19.1094 2.12943C22.2034 0.343099 26.0154 0.343099 29.1094 2.12943L42.4921 9.85592C45.5861 11.6423 47.4921 14.9435 47.4921 18.5162V33.9692C47.4921 37.5418 45.5861 40.8431 42.4921 42.6294L29.1094 50.3559C26.0154 52.1423 22.2034 52.1423 19.1094 50.3559L5.72669 42.6294C2.63268 40.8431 0.726688 37.5418 0.726688 33.9692V18.5162C0.726688 14.9435 2.63268 11.6423 5.72669 9.85592L19.1094 2.12943Z" fill="#FF5200"></path></svg>,
            icon: <i class="fa-solid fa-indian-rupee-sign "></i>
        },
        {
            type: "Total  Vendor",
            amount: "37,802",
            bg: <svg xmlns="http://www.w3.org/2000/svg" width="48" height="52" viewBox="0 0 48 52" fill="none"><path d="M19.1094 2.12943C22.2034 0.343099 26.0154 0.343099 29.1094 2.12943L42.4921 9.85592C45.5861 11.6423 47.4921 14.9435 47.4921 18.5162V33.9692C47.4921 37.5418 45.5861 40.8431 42.4921 42.6294L29.1094 50.3559C26.0154 52.1423 22.2034 52.1423 19.1094 50.3559L5.72669 42.6294C2.63268 40.8431 0.726688 37.5418 0.726688 33.9692V18.5162C0.726688 14.9435 2.63268 11.6423 5.72669 9.85592L19.1094 2.12943Z" fill="#CBD5E1"></path></svg>,
            icon: <i class="fa-solid fa-shop "></i>
        },
        {
            type: "Total  User",
            amount: "37,802",
            bg: <svg xmlns="http://www.w3.org/2000/svg" width="48" height="52" viewBox="0 0 48 52" fill="none"><path d="M19.1094 2.12943C22.2034 0.343099 26.0154 0.343099 29.1094 2.12943L42.4921 9.85592C45.5861 11.6423 47.4921 14.9435 47.4921 18.5162V33.9692C47.4921 37.5418 45.5861 40.8431 42.4921 42.6294L29.1094 50.3559C26.0154 52.1423 22.2034 52.1423 19.1094 50.3559L5.72669 42.6294C2.63268 40.8431 0.726688 37.5418 0.726688 33.9692V18.5162C0.726688 14.9435 2.63268 11.6423 5.72669 9.85592L19.1094 2.12943Z" fill="#2377FC"></path></svg>,
            icon: <i class="fa-solid fa-users "></i>
        },

    ]

    const handledata = async () => {
        let res = await superadmingetapi('dashboard', token)
        console.log(res.data)
        setdata(res.data)
    }


    useEffect(() => {
        handledata()
    }, [])


    const vendordata = [
        {
            type: "weekly",
            title: "Weekly Vendor",
            img: "https://cdn-icons-png.freepik.com/256/3690/3690650.png?ga=GA1.1.1847521822.1723448167&semt=ais_hybrid"
        },
        {
            type: "monthly",
            title: "Monthly Vendor",
            img: "https://cdn-icons-png.freepik.com/256/17641/17641134.png?ga=GA1.1.1847521822.1723448167&semt=ais_hybrid"
        },
        {
            type: "total",
            title: "Total Vendor",
            img: "https://cdn-icons-png.freepik.com/256/3733/3733928.png?ga=GA1.1.1847521822.1723448167&semt=ais_hybrid"
        },
    ]


    const orderdata = [
        {
            type: "weeklycount",
            title: "Weekly Orders",
            img: "https://cdn-icons-png.freepik.com/256/3296/3296541.png?ga=GA1.1.1847521822.1723448167&semt=ais_hybrid"
        },
        {
            type: "monthlycount",
            title: "Monthly Orders",
            img: "https://cdn-icons-png.freepik.com/256/4141/4141453.png?ga=GA1.1.1847521822.1723448167&semt=ais_hybrid"
        },
        {
            type: "totalcount",
            title: "Total Orders",

            img: "https://cdn-icons-png.freepik.com/256/6054/6054069.png?ga=GA1.1.1847521822.1723448167&semt=ais_hybrid"
        },
    ];


    const usersdata = [
        {
            type: "weekly",
            title: "Weekly Users",
            img: "https://cdn-icons-png.freepik.com/256/2195/2195471.png?ga=GA1.1.1847521822.1723448167&semt=ais_hybrid"
        },
        {
            type: "monthly",
            title: "Monthly Users",
            img: "https://cdn-icons-png.freepik.com/256/16253/16253549.png?ga=GA1.1.1847521822.1723448167&semt=ais_hybrid"
        },
        {
            type: "total",
            title: "Total Users",

            img: "https://cdn-icons-png.freepik.com/256/3239/3239147.png?ga=GA1.1.1847521822.1723448167&semt=ais_hybrid"
        },
    ];

    const admindata = [
        {
            type: "weekly",
            title: "Weekly Admin",
            img: "https://cdn-icons-png.freepik.com/256/5433/5433990.png?ga=GA1.1.1847521822.1723448167&semt=ais_hybrid"
        },
        {
            type: "monthly",
            title: "Monthly Admin",
            img: "https://cdn-icons-png.freepik.com/256/10618/10618321.png?ga=GA1.1.1847521822.1723448167&semt=ais_hybrid"
        },
        {
            type: "total",
            title: "Total Admin",

            img: "https://cdn-icons-png.freepik.com/256/16133/16133851.png?ga=GA1.1.1847521822.1723448167&semt=ais_hybrid"
        },
    ];

    return (
        <>
            {/* <section>
                <div className="container">
                    <div className="grid grid-cols-3 gap-4">
                        <div className="col-span-2">
                            <div className="grid grid-cols-2 gap-4">
                                {dashdata.map((item, index) => (
                                    <div key={index} className="col-span-1">
                                        <div className="bg-white shadow rounded-2xl">
                                            <div className="flex items-center gap-4 p-6 relative">
                                                <div className="">
                                                    {item.bg}
                                                    <div className={`icondash${index + 1} icondash`}>
                                                        {item.icon}
                                                    </div>
                                                </div>
                                                <div className="dashcontent">
                                                    <h5 className="body-text mb-1">{item.type}</h5>
                                                    <h4>{item.amount}</h4>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className="col-span-1">
                            <div className="bg-white shadow p-1 rounded-2xl">
                                <ApexChartComponent salesData={salesData} categories={categories} />
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}

            {data ? <section>
                <div className="container">
                    <div className="flex flex-wrap">
                        <div className="w-full lg:w-5/5 p-4">
                            <div className="space-y-4">


                                <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                                    {/* Total Orders Card */}


                                    <div className="col-span-3">
                                        {/* <h2 className='font-semibold text-2xl'>Vendors</h2> */}
                                    </div>
                                    {data.vendors &&
                                        vendordata.map((item, index) => {
                                            return (
                                                <DashboardComp data={data.vendors} img={item.img} type={item.type} title={item.title} value="vendors" />
                                            );
                                        })}



                                    <div className="col-span-3">
                                        {/* <h2 className='font-semibold text-2xl mb-0'>Orders</h2> */}
                                    </div>
                                    {data.orders &&
                                        orderdata.map((item, index) => {
                                            return (
                                                <DashboardComp data={data.orders} img={item.img} type={item.type} title={item.title} />
                                            );
                                        })}



                                    <div className="col-span-3">
                                        {/* <h2 className='font-semibold text-2xl'>Users</h2> */}
                                    </div>
                                    {data.users &&
                                        usersdata.map((item, index) => {
                                            return (
                                                <DashboardComp data={data.users} img={item.img} type={item.type} title={item.title} />
                                            );
                                        })}

                                    <div className="col-span-3">
                                        {/* <h2 className='font-semibold text-2xl'>Admin</h2> */}
                                    </div>
                                    {data.users &&
                                        admindata.map((item, index) => {
                                            return (
                                                <DashboardComp data={data.admins} img={item.img} type={item.type} title={item.title} />
                                            );
                                        })}
                                </div>
                            </div>
                        </div>

                        <div className="w-full lg:w-3/5 p-4">

                            <div className="bg-white shadow rounded-lg">
                                <div className="p-4">
                                    <h4 className="text-lg font-semibold">Sales</h4>
                                    {!data.sales ? <Loader /> : <PerformanceChart salesData={data.sales} />}

                                </div>
                            </div>

                        </div>
                        <div className="w-full lg:w-2/5 p-4">

                            <div className="bg-white shadow rounded-lg">
                                <div className="p-4">
                                    <h4 className="text-lg font-semibold">Orders</h4>
                                    {!data.orders ? <Loader /> : <ApexChart orders={data.orders} />}

                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </section> : <Loader />}
        </>
    );
}

export default Dashboard;
