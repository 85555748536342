import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { superadmingetapi, superadminputapi } from '../../Api/Api';
import Notfound from '../../component/Notfound';
import Loader from '../../component/Loader';
import TitleComp from '../../component/TitleComp';
import { BASE_URL } from '../../Api/BaseUrl';
import imagenot from "../../assets/images/notfound.avif";
import veg from "../../assets/images/veg.png";
import nonveg from "../../assets/images/nonveg.jpg";
import convertDateToMonthName from '../../component/FormDate';

const truncateText = (text, wordLimit) => {
    const words = text?.split(' ');
    if (words.length > wordLimit) {
        return words.slice(0, wordLimit).join(' ') + '...';
    }
    return text;
};

function ProductVariantList() {
    const { id } = useParams();
    let token = localStorage.getItem("token");
    const [loading, setloading] = useState(false);
    const [search, setsearch] = useState("")
    const [filterdata, setfilterdata] = useState([])
    const [data, setdata] = useState([]);

    let handleget = async () => {
        setloading(true);
        let res = await superadmingetapi(`product_variant?product=${id}`, token);
        console.log(res);
        if (!res.error) {
            setdata(res.data);
        }
        setloading(false);
    };

    useEffect(() => {
        handleget();
    }, []);

    const handleCheckboxChange = async (itemId, status) => {
        setloading(true);
        let requestdata = {
            _id: itemId,
            is_disabled: !status
        }
        let res = await superadminputapi(`product_variant`, requestdata, token);

        if (!res.error) {
            setloading(false)
            handleget()
        }
        setloading(false);
    };

    const handleFilter = (e) => {
        e.preventDefault();
        const filteredData = data.filter((item) =>
            item?.name.toLowerCase().includes(search.toLowerCase())
        );
        console.log(filteredData);
        setfilterdata(filteredData);
    };


    useEffect(() => {
        if (data) {
            setfilterdata(data)
        }
    }, [data])

    return (
        <>
            {loading && <Loader />}
            <div className="grid grid-cols-3 gap-5">
                <div className="col-span-3">
                    <TitleComp title="Product Variant List" />
                </div>
                <div className="col-span-1">
                    <form onSubmit={handleFilter}>
                        <div className="searchbar">
                            <div class="InputContainer">
                                <input type="text" value={search} onChange={(e) => setsearch(e.target.value)} name="text" id="input" placeholder="Search" />
                                <div class="border"></div>

                                <button class="micButton"><svg viewBox="0 0 512 512" class="searchIcon"><path d="M416 208c0 45.9-14.9 88.3-40 122.7L502.6 457.4c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L330.7 376c-34.4 25.2-76.8 40-122.7 40C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208zM208 352a144 144 0 1 0 0-288 144 144 0 1 0 0 288z"></path></svg>
                                </button>

                            </div>
                        </div>
                    </form>
                </div>
                <div className="col-span-1">
                    <button className='btn bg-blue-500 text-sm px-5 py-2 hover:bg-blue-600 hover:shadow-sm text-white rounded-md w-max-w' onClick={(e) => { setfilterdata(data); setsearch("") }}>Clear All</button>
                </div>
            </div>
            {filterdata.length > 0 ? (
                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-4 gap-6">
                    {filterdata.map((item) => {
                        // let truncatedDescription = truncateText(item.description, 5);
                        return (
                            <div className="col-span-1" key={item._id}>

                                <>
                                    <div className="col-span-1">
                                        <div class="card">
                                            <div class="image-container">

                                                <img
                                                    src={`${BASE_URL}${item.product.image.file}`}
                                                    alt={item.name}
                                                    loading='lazy'
                                                    onError={(e) => e.target.src = imagenot}
                                                    className="w-full h-full object-cover"
                                                />

                                                <div class="price">  <del className='text-red-600'>₹{item.mrp}</del> <span className='text-emerald-600'>₹{item.selling_price}</span></div>
                                            </div>

                                            {item?.connected_app_category?.name == "Restaurants" && <label className="favorite">
                                                <img src={item.veg ? veg : nonveg} alt="" />
                                            </label>}

                                            <div class="content">
                                                <div class="brand flex justify-between">
                                                    <div className="">
                                                        {item.store.name}
                                                    </div>
                                                    <label htmlFor="" className="enablecheck bg-white">
                                                        <div className="checkbox-wrapper-19">
                                                            <input
                                                                id={`cbtest-19-${item._id}`}
                                                                type="checkbox"
                                                                checked={!item.is_disabled}
                                                                onChange={() => handleCheckboxChange(item._id, item.is_disabled)}
                                                            />
                                                            <label className="check-box" htmlFor={`cbtest-19-${item._id}`}></label>
                                                        </div>
                                                    </label></div>
                                                <div class="product-name mb-1  " >{item.product.name}  <span className='text-emerald-400 text-lg'> ({item.unit_weight}{item.units.name != "other" && item.units.name})  </span></div>

                                                <div className="product-name">
                                                    <span>HSN : {item.hsn_number}</span>
                                                    <h6 className='text-gray-600'>Created At : {convertDateToMonthName(item.created_at)}</h6>
                                                </div>




                                            </div>

                                            {/* <div className="mb-3">
                                                <div className={`flex gap-6 ps-2 `}>

                                                    {item.user.name && <div class="uiverse">
                                                        <span class="tooltip1">User</span>
                                                        <span className='tooltipname'>
                                                            <i class="fa-solid fa-user"></i> {item.user.name}
                                                        </span>
                                                    </div>}

                                                    {item.vendor.name && <div class="uiverse">
                                                        <span class="tooltip1">Vendor</span>
                                                        <span className='tooltipname'>
                                                            <i class="fa-solid fa-shop"></i> {item.vendor.name}
                                                        </span>
                                                    </div>}
                                                </div>
                                            </div> */}




                                        </div>
                                    </div>
                                </>


                            </div>
                        );
                    })}
                </div >
            ) : (
                <div className="grid grid-cols-3 justify-center">
                    <div className="col-span-1"></div>
                    <div className="col-span-1">
                        <Notfound title="Product Variant Not Found" />
                    </div>
                    <div className="col-span-1"></div>
                </div>
            )
            }
        </>
    );
}

export default ProductVariantList;
